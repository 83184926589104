import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/test",
    name: "test",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/test.vue"),
  },
  {
    path: "/aboutPlane",
    name: "aboutPlane",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutPlane.vue"),
  },
  {
    path: "/joinPlane",
    name: "joinPlane",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/joinPlane.vue"),
  },
  {
    path: "/mechanism",
    name: "mechanism",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mechanism.vue"),
  },
  {
    path: "/whitePaper",
    name: "whitePaper",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/whitePaper.vue"),
  },
  {
    path: "/planeNews",
    name: "planeNews",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/planeNews.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/contactUs.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
