<template>
  <div>
    <headerNav />
    <div class="fullpage_nav">
      <span class="fullpagebox animate__animated animate__bounceInDown">
        <a
          :href="'#' + item.id"
          v-for="item in fullpageNavList"
          :key="item.id"
          @click="tabPage(item)"
          :class="activePage === item.id ? 'fullpage_nav_item_active' : ''"
          class="fullpage_nav_item"
        >
          {{ item.name }}
        </a>
      </span>
    </div>

    <div id="fullpage">
      <div
        class="section item1"
        :style="{ backgroundImage: 'url(' + backimg01 + ')' }"
      >
        <echartsMap :screenWidth="screenWidth" />

        <div class="page_introduce setwidth">
          <div class="color_line animate__animated animate__bounceInLeft" :style="{'animation-duration': 0.5 + 's'}">虚拟现实世界所有能虚拟的一切到另一个星球</div>
          <div class="color_line animate__animated animate__bounceInLeft" :style="{'animation-duration': 1 + 's'}">这是我们的平行宇宙</div>
          <div class="color_line animate__animated animate__bounceInLeft" :style="{'animation-duration': 1.5 + 's'}">和地球“一模一样”的虚拟星球</div>
          <div class="color_line animate__animated animate__bounceInLeft" :style="{'animation-duration': 2 + 's'}">因为能从收入和娱乐上让我们更加快乐</div>
          <div class="color_line animate__animated animate__bounceInLeft" :style="{'animation-duration': 2.5 + 's'}">所以叫快乐星球</div>
          <div class="color_line animate__animated animate__bounceInLeft" :style="{'animation-duration': 3 + 's'}">我们要打破现实中一切不合理的限制</div>
          <div class="color_line animate__animated animate__bounceInLeft" :style="{'animation-duration': 3.5 + 's'}">我们旨在创造一个全新的世界</div>
        </div>
      </div>

      <div
        class="section item2"
        :style="{ backgroundImage: 'url(' + backimg02 + ')' }"
      >
        <div id="item2">
          <div class="page_introduce">
            <div class="color_line">
              上班时下班后，您可随时随地进入另一世界工作、生
            </div>
            <div class="color_line">活、娱乐、交友、购物等等等等</div>
            <div class="color_line">
              当然，您也可以在快乐星球再结一次婚，至于新郎新
            </div>
            <div class="color_line">娘是否为原配可能您永远不得而知。</div>
            <div class="color_line">
              这是一个囊括了微信、京东、拼多多、淘宝、QQ、
            </div>
            <div class="color_line">抖音等所有网络平台功能的个新的世界。</div>
          </div>

        </div>
      </div>

      <div
        class="section item3"
        :style="{ backgroundImage: 'url(' + backimg03 + ')' }"
      >
        <div id="item3">

          <div class="fullpage_text">
            <div class="textimg_box">
              <div
                      class="text_content"
                      style="margin-left: 10%"
                      :style="{ backgroundImage: 'url(' + textimg01 + ')' }"
              >
                <div class="text_font">全方位打造虚拟现实</div>
              </div>
            </div>
            <div class="textimg_box">
              <div
                      class="text_content"
                      style="float: right; margin-right: 10%"
                      :style="{ backgroundImage: 'url(' + textimg03 + ')' }"
              >
                <div style="margin-left: 0; margin-right: 18%" class="text_font">
                  现实场景虚拟网络化
                </div>
              </div>
            </div>
            <div class="textimg_box">
              <div
                      class="text_content"
                      style="margin-left: 15%"
                      :style="{ backgroundImage: 'url(' + textimg02 + ')' }"
              >
                <div class="text_font">全球性虚拟互动实现</div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div
        class="section item4"
        :style="{ backgroundImage: 'url(' + backimg04 + ')' }"
      >
        <div id="item4">

          <div class="fullpage_xn">
            <el-row>
              <el-col :span="6" v-if="isPersonimg">
                <div class="person_left">
                  <img :src="person01" alt="" />
                </div>
              </el-col>
              <el-col :span="isPersonimg ? 12 : 24">
                <div class="xn_center">
                  <img class="centerimg" :src="centerimg" alt="" />
                  <div class="center_font">去中心化</div>

                  <div class="border_outer">
                    <div class="border_inner">
                      <div
                              class="textfont01"
                              style="right: -50px; top: 40px"
                              :style="{ backgroundImage: 'url(' + textfontimg + ')' }"
                      >
                        内容应用端
                      </div>
                      <div
                              style="left: 30%; bottom: -18px"
                              class="textfont01"
                              :style="{ backgroundImage: 'url(' + textfontimg + ')' }"
                      >
                        全新社会属性
                      </div>
                      <div
                              style="left: -50px; top: 40px"
                              class="textfont01"
                              :style="{ backgroundImage: 'url(' + textfontimg + ')' }"
                      >
                        区块链技术WEB3
                      </div>
                    </div>
                  </div>

                  <img class="leftimg" :src="leftimg" alt="" />
                  <div class="centerfont_font_left">
                    <div>现实世界</div>
                    <div>(REALITY)</div>
                  </div>

                  <img class="rightimg" :src="rightimg" alt="" />
                  <div class="centerfont_font_right">
                    <div>虚拟世界</div>
                    <div>(VIRTUALWORLD)</div>
                  </div>

                  <!-- <div></div> -->
                </div>
              </el-col>
              <el-col :span="6" v-if="isPersonimg">
                <div class="person_right">
                  -
                  <img :src="person02" alt="" />
                </div>
              </el-col>
            </el-row>
          </div>

        </div>
      </div>

      <div
        class="section item5"
        style="background-color: black; background-size: auto"
        :style="{ backgroundImage: 'url(' + backimg05 + ')' }"
      >
        <div id="item5">

          <div
                  style="
            display: flex;
            justify-content: space-around;
            margin-bottom: 30px;
            margin-top: 60px;
          "
          >
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton03 + ')' }"
            >
              <div>娱乐</div>
            </div>
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton04 + ')' }"
            >
              <div>工作</div>
            </div>
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton05 + ')' }"
            >
              <div>社交</div>
            </div>
          </div>
          <div
                  style="
            display: flex;
            justify-content: space-around;
            margin-bottom: 30px;
            margin-top: 30px;
          "
          >
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton01 + ')' }"
            >
              <div>身份</div>
            </div>
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton01 + ')' }"
            >
              <div>学习</div>
            </div>
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton03 + ')' }"
            >
              <div>购物</div>
            </div>
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton04 + ')' }"
            >
              <div>文明</div>
            </div>
          </div>
          <div
                  style="
            display: flex;
            justify-content: space-around;
            margin-bottom: 30px;
            margin-top: 30px;
          "
          >
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton05 + ')' }"
            >
              <div>探索</div>
            </div>
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton04 + ')' }"
            >
              <div>未知</div>
            </div>
            <div
                    class="five_button"
                    :style="{ backgroundImage: 'url(' + botton02 + ')' }"
            >
              <div>情感</div>
            </div>
          </div>

        </div>
      </div>

      <div
        class="section sectionbg06 item6"
        style="
          background-color: black;
          background-size: auto;
          background-position: center 100%;
          overflow-y: none;
        "
        :style="{ backgroundImage: 'url(' + backimg06 + ')' }"
      >
        <div id="item6">

          <div class="purpose">
            <div class="purposeimg">
              <div
                      class="purpose_box"
                      :style="{ backgroundImage: 'url(' + purposebox + ')' }"
              >
                <div class="purpose_boxtext01">共建</div>
                <!-- <div class="purpose_boxtext02">吉章投资初创平台</div> -->
                <div class="purpose_boxtext02">未来星球公民共同建设</div>
              </div>
              <div
                      class="purpose_box"
                      :style="{ backgroundImage: 'url(' + purposebox + ')' }"
              >
                <div class="purpose_boxtext01">共享</div>
                <div class="purpose_boxtext02">享有星球所有的权力和义务，</div>
                <div class="purpose_boxtext02">星球服务</div>
              </div>
              <div
                      class="purpose_box"
                      :style="{ backgroundImage: 'url(' + purposebox + ')' }"
              >
                <div class="purpose_boxtext01">共治</div>
                <div class="purpose_boxtext02" style="margin-top: 30px">
                  在共识机制下共同治理星球
                </div>
              </div>
            </div>
            <!-- <div class="color_line">第六页</div> -->
          </div>

        </div>
      </div>
      <div
        class="section item7"
        style="background-color: black"
        :style="{ backgroundImage: 'url(' + backimg07 + ')' }"
      >
        <div id="item7">

          <div class="seven_Page" v-if="isPersonimg">
            <div style="margin-top: 80px">
              <el-row>
                <el-col :span="8">
                  <div class="seven_text" style="margin-left: 40%">
                  <span style="font-size: 30px; font-weight: 700"
                  >传统企业</span
                  >
                    <span>变通产业布局</span>
                    <span>增加收入项</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="seven_text">
                  <span style="font-size: 30px; font-weight: 700"
                  >传统企业家</span
                  >
                    <span>资产增值</span>
                    <span>私有化</span>
                  </div>
                </el-col>
                <el-col :span="10">
                  <div
                          class="seven_textbg"
                          :style="{ backgroundImage: 'url(' + seventextbg + ')' }"
                  >
                    <div class="senven_tbgt" style="margin-top: 110px">新质</div>
                    <div class="senven_tbgt">生产力</div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div style="margin-top: 60px">
              <el-row>
                <el-col :span="8">
                  <div class="seven_text" style="margin-left: 40%">
                  <span style="font-size: 30px; font-weight: 700"
                  >社会作用</span
                  >
                    <span>解决就业</span>
                    <span>全民收入增加</span>
                    <span>缓解社会焦虑/社会矛盾</span>
                    <span>解放生产力</span>
                    <span>促进社会进步</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="seven_text">
                  <span style="font-size: 30px; font-weight: 700"
                  >个人价值</span
                  >
                    <span>独一无二的“社会”归属</span>
                  </div>
                </el-col>
                <el-col :span="10"> </el-col>
              </el-row>
            </div>
          </div>

          <div class="seven_Page" v-if="!isPersonimg">
            <div
                    class="seven_textbg"
                    :style="{ backgroundImage: 'url(' + seventextbg + ')' }"
            >
              <div
                      class="senven_tbgt"
                      style="margin-top: 110px; padding-top: 100px"
              >
                新质
              </div>
              <div class="senven_tbgt">生产力</div>
            </div>

            <div style="margin-top: -60px">
              <el-row>
                <el-col :span="6">
                  <div class="seven_text">
                  <span style="font-size: 12px; font-weight: 700"
                  >传统企业</span
                  >
                    <span>变通产业布局</span>
                    <span>增加收入项</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="seven_text">
                  <span style="font-size: 12px; font-weight: 700"
                  >传统企业家</span
                  >
                    <span>资产增值</span>
                    <span>私有化</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="seven_text">
                  <span style="font-size: 14px; font-weight: 700"
                  >社会作用</span
                  >
                    <span>解决就业</span>
                    <span>全民收入增加</span>
                    <span>缓解社会焦虑/社会矛盾</span>
                    <span>解放生产力</span>
                    <span>促进社会进步</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="seven_text">
                  <span style="font-size: 14px; font-weight: 700"
                  >个人价值</span
                  >
                    <span>独一无二的“社会”归属</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>

        </div>
      </div>
      <div
        class="section item8"
        style="background-color: black; background-size: 98% 85%"
        :style="{ backgroundImage: 'url(' + backimg08 + ')' }"
      >
        <div id="item8">

          <div class="eight_Page">
            <div
                    class="item8-main"
                    style="position: absolute; top: 20%; left: 10%; display: flex"
            >
              <div
                      class="item8-img"
                      style="width: 90px; height: 65px; background-size: 100% 100%"
                      :style="{ backgroundImage: 'url(' + eighttbg + ')' }"
              ></div>
              <div
                      class="item8-title"
                      style="font-size: 44px; color: #ffd668; margin-left: 10px"
              >
                <span>共治</span> <span style="font-size: 28px"> 去中心化</span>
              </div>
            </div>
            <div class="eight_page01">
              <div>初建</div>
              <div style="margin-bottom: 20px">搭建起平台</div>
              <div
                      class="eight_img"
                      :style="{ backgroundImage: 'url(' + eighttbg01 + ')' }"
              >
                <div class="textdiv">01</div>
              </div>
            </div>
            <div class="eight_page02">
              <div
                      class="eight_img"
                      :style="{ backgroundImage: 'url(' + eighttbg02 + ')' }"
              >
                <div class="textdiv">02</div>
              </div>
              <div>支撑</div>
              <div>技术支撑和辅助建设</div>
            </div>
            <div class="eight_page03">
              <div>脱离</div>
              <div style="margin-bottom: 20px">身份抽离丶加入共建</div>
              <div
                      class="eight_img"
                      :style="{ backgroundImage: 'url(' + eighttbg03 + ')' }"
              >
                <div class="textdiv">03</div>
              </div>
            </div>
            <div class="eight_page04">
              <div
                      class="eight_img"
                      :style="{ backgroundImage: 'url(' + eighttbg04 + ')' }"
              >
                <div class="textdiv">04</div>
              </div>
              <div>共治</div>
              <div>技术支撑和辅助建设</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "fullpage.js/dist/fullpage.css";
import fullpage from "fullpage.js";
import headerNav from "./headerNav.vue";
import echartsMap from "./echartsMap.vue";
export default {
  components: {
    headerNav,
    echartsMap,
  },
  data() {
    return {
      isPersonimg: true,
      screenWidth: "",
      full: null,
      activePage: "firstPage",
      aaa: 'firstPage',
      fullpageNavList: [
        {
          name: "星球由来",
          id: "firstPage",
        },
        {
          name: "未来展望",
          id: "secondPage",
        },
        {
          name: "概念阐述",
          id: "thirdPage",
        },
        {
          name: "核心思想",
          id: "fourPage",
        },
        {
          name: "功能实现",
          id: "fivePage",
        },
        {
          name: "理念宗旨",
          id: "sixPage",
        },
        {
          name: "深远意义",
          id: "sevenPage",
        },
        {
          name: "展望",
          id: "eightPage",
        },
      ],
      backimg01: require("@/assets/img/切图_01.png"),
      backimg02: require("@/assets/img/切图_02.png"),
      backimg03: require("@/assets/img/切图_03.png"),
      backimg04: require("@/assets/img/切图_04.png"),
      backimg05: require("@/assets/img/功能实现.png"),
      backimg06: require("@/assets/img/理念宗旨bg.png"),
      backimg07: require("@/assets/img/深远意义bg.png"),
      backimg08: require("@/assets/img/展望-线条.png"),

      textimg01: require("@/assets/img/文本框-1.png"),
      textimg02: require("@/assets/img/文本框-2.png"),
      textimg03: require("@/assets/img/文本框-3.png"),

      person01: require("@/assets/img/现实世界.png"),
      person02: require("@/assets/img/虚拟世界.png"),

      centerimg: require("@/assets/img/去中心化.png"),

      leftimg: require("@/assets/img/文字背景框2.png"),
      rightimg: require("@/assets/img/文字背景框2-1.png"),

      textfontimg: require("@/assets/img/文字背景框.png"),
      purposebox: require("@/assets/img/理念宗旨文本框bg.png"),
      seventextbg: require("@/assets/img/深远背景文本框bg.png"),
      eighttbg01: require("@/assets/img/展望-1.png"),
      eighttbg02: require("@/assets/img/展望-2.png"),
      eighttbg03: require("@/assets/img/展望-3.png"),
      eighttbg04: require("@/assets/img/展望-4.png"),
      eighttbg: require("@/assets/img/共治.png"),
      botton01: require("@/assets/img/橙.png"),
      botton02: require("@/assets/img/粉.png"),
      botton03: require("@/assets/img/青.png"),
      botton04: require("@/assets/img/黄.png"),
      botton05: require("@/assets/img/紫.png"),
    };
  },

  watch: {
    screenWidth: {
      handler: function (val) {
        console.log(val);
        console.log("0000000000");
        if (val < 992) {
          this.isPersonimg = false;
        } else {
          this.isPersonimg = true;
        }

        console.log(this.isPersonimg);
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.screenWidth = document.body.clientWidth;

    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    this.$nextTick(() => {
      this.screenWidth = document.body.clientWidth;

      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth;
        })();
      };
    });

    this.full = new fullpage(document.getElementById("fullpage"), {
      // 设置为垂直方向滚动
      afterLoad: this.afterLoad,
      beforeLeave: this.beforeLoad,
      scrollingSpeed: 700, // 滚动速度，单位为毫秒
      easing: "easeInOutQuart", // 定义滚动动画
      menu: "#menu", // 指定菜单，每个菜单项对应一个滑块
      anchors: [
        "firstPage",
        "secondPage",
        "thirdPage",
        "fourPage",
        "fivePage",
        "sixPage",
        "sevenPage",
        "eightPage",
      ],
      // 设置是否需要循环滚动
      // loopTop: true, // 是否循环到第一页
      // loopBottom: true, // 是否循环到最后一页

      // 设置是否需要键盘控制
      keyboardScrolling: true, // 使用键盘（37, 38, 39, 40）控制滚动

      // 设置是否需要鼠标滚轮控制
      mouseWheel: true, // 启用鼠标滚轮控制
      scrollBar: false, // 是否显示滚动条

      // 设置是否需要touch控制
      touchSensitivity: 15, // 触摸平板电脑的敏感度

      // 设置是否需要自动滚动
      autoScrolling: true, // 是否使用插件的自动滚动功能
      fitToSection: true, // 是否自动调整到最佳高度
    });
  },
  methods: {
    beforeLoad(origin, destination, direction) {
      console.log(origin, destination, direction)
      this.aaa = destination.anchor
    },
    afterLoad(origin, destination, direction) {
      this.activePage = destination.anchor;
      console.log(this.activePage)
      if(this.activePage === 'secondPage') {
        let element = document.getElementById('item2');
        element.classList.add('animate__animated');
        element.classList.add('animate__zoomIn');
      }else if(this.activePage === 'thirdPage') {
        let element = document.getElementById('item3');
        element.classList.add('animate__animated');
        element.classList.add('animate__zoomIn');
      }else if(this.activePage === 'fourPage') {
        let element = document.getElementById('item4');
        element.classList.add('animate__animated');
        element.classList.add('animate__zoomIn');
      }else if(this.activePage === 'fivePage') {
        let element = document.getElementById('item5');
        element.classList.add('animate__animated');
        element.classList.add('animate__zoomIn');
      }else if(this.activePage === 'sixPage') {
        let element = document.getElementById('item6');
        element.classList.add('animate__animated');
        element.classList.add('animate__zoomIn');
      }else if(this.activePage === 'sevenPage') {
        let element = document.getElementById('item7');
        element.classList.add('animate__animated');
        element.classList.add('animate__zoomIn');
      }else if(this.activePage === 'eightPage') {
        let element = document.getElementById('item8');
        element.classList.add('animate__animated');
        element.classList.add('animate__zoomIn');
      }
    },
    tabPage(item) {
      this.activePage = item.id;
    },
  },
  beforeDestroy() {
    this.full.destroy("all");
  },
};
</script>
<style scoped>
  .item8 >>> .fp-overflow {
    width: 100%;
    height: 100%;
  }
  #item8{
    width: 100%;
    height: 100%;
  }
</style>
<style lang="less" scoped>
a {
  text-decoration: none;
  color: #fff;
}
.section {
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .setwidth {
    width: 40%;
  }
  .page_introduce {
    // position: absolute;
    margin-left: 60px;
    display: inline-block;
    letter-spacing: 5px;
    line-height: 30px;
    .color_line {
      padding: 4px 0;
      font-weight: 700;
      background: linear-gradient(
        to right,
        #409eff,
        #ebffd1c7
      ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/

      -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/

      -webkit-text-fill-color: transparent; /*给文字设置成透明*/
    }
  }
  .fullpage_text {
    margin-top: 30px;
    .textimg_box {
      overflow: hidden;
      .text_content {
        height: 155px;
        width: 500px;
        background-size: cover;
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        .text_font {
          background: linear-gradient(
            to bottom,
            #a9eae4,
            #5dc8f1
          ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/

          -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/

          -webkit-text-fill-color: transparent; /*给文字设置成透明*/

          font-size: 2rem;
          font-weight: 700;
          margin-left: 15%;
          padding-bottom: 24px;
        }
      }
    }
  }
  .fullpage_xn {
    // height: 100vh;
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    .person_left {
      width: 35vw;
      text-align: right;
      img {
        padding-top: 75px;
        height: 500px;
      }
    }
    .person_right {
      width: 540px;
      position: relative;
      img {
        position: absolute;
        left: -175px;
        padding-top: 75px;
        height: 500px;
      }
    }
    .xn_center {
      margin-top: 180px;
      position: relative;
      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      .centerimg {
        height: 260px;
        width: 260px;
        position: absolute;
        top: -30%;
      }
      .center_font {
        color: #fff;
        font-size: 20px;
        position: absolute;
        top: 10px;
        background-color: rgba(48, 79, 129, 0.3);
        padding: 5px 20px;
      }

      .leftimg {
        height: 190px;
        width: 230px;
        position: absolute;
        left: 0px;
        top: 18px;
      }

      .rightimg {
        height: 190px;
        width: 230px;
        position: absolute;
        right: 0px;
        top: 18px;
      }

      .centerfont_font_left {
        text-align: center;
        color: #fff;
        font-size: 16px;
        position: absolute;
        left: 58px;
        div {
          padding: 5px 0;
        }
      }
      .centerfont_font_right {
        text-align: center;
        color: #fff;
        font-size: 16px;
        position: absolute;
        right: 29px;
        div {
          padding: 5px 0;
        }
      }
      .textfont01 {
        position: absolute;
        width: 102px;
        height: 44px;
        background-size: cover;
        color: #fff;
        line-height: 43px;
        text-align: center;
        font-size: 12px;
      }
    }
    .border_outer {
      height: 300px;
      width: 600px;
      border-radius: 100%;
      border: 1px dashed #fff;

      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
    }
    .border_inner {
      position: relative;
      height: 130px;
      width: 250px;
      border-radius: 100%;
      border: 1px dashed #fff;
    }
  }
}
.textright {
  text-align: right;
}
.marright {
  margin-right: 24px;
}

.header_nav {
  position: fixed;
  top: 0;
  background: #000;
  z-index: 2;
  width: 100%;
  padding: 12px 0px;
  .nav_title {
    margin: 0 6px 0 24px;
    font-size: 24px;
    font-weight: 700;
    font-family: cursive;
    background: linear-gradient(
      to bottom,
      #c805ff,
      #2549fc
    ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/

    -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/

    -webkit-text-fill-color: transparent; /*给文字设置成透明*/
    vertical-align: middle;
  }
  .nav_item {
    padding: 2px 6px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin: 0 6px;
  }
  .download_item {
    padding: 2px 6px;
    background: #fff;
    color: #333;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin: 0 6px;
  }
}
.fullpage_nav {
  position: fixed;
  top: 60px;
  width: 100%;
  text-align: center;
  z-index: 2;

  .fullpagebox {
    text-align: center;
    border-radius: 30px;
    background-color: rgba(227, 227, 234, 0.3);
    display: inline-block;
    padding: 4px 0;
  }
  .fullpage_nav_item {
    color: #fff;
    font-size: 14px;
    padding: 4px 20px;
    border-radius: 30px;
    cursor: pointer;
  }
  .fullpage_nav_item_active {
    background-color: rgba(234, 237, 242, 0.3);
    cursor: pointer;
  }
}

.purposeimg {
  display: flex;
  // margin-top: -220px;
  justify-content: space-evenly;
  color: #fff;
  .purpose_box {
    height: 149px;
    width: 308px;
    background-size: cover;

    text-align: center;
    .purpose_boxtext01 {
      font-size: 32px;
      font-weight: 700;
      margin-top: 25px;
    }
    .purpose_boxtext02 {
      background: linear-gradient(to bottom, #a9eae4, #5dc8f1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 18px;
      margin-top: 10px;
    }
  }
}

.seven_Page {
  color: #fff;
  .seven_text {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 50px;
    text-align: left;
  }
  .seven_textbg {
    height: 400px;
    position: absolute;
    top: 100px;
    width: 410px;
    background-size: cover;
    margin: 0;
  }
  .senven_tbgt {
    text-align: center;
    font-size: 52px;
    font-weight: 700;
  }
}
.eight_Page {
  color: #fff;
  .eight_page01 {
    font-size: 30px;
    font-weight: 700;
    color: #67f9ff;
    .eight_img {
      height: 171px;
      width: 100%;

      background-size: cover;
      background-position: center;
    }
    text-align: center;
    position: absolute;
    bottom: 4%;
    left: 8%;
    .textdiv {
      font-size: 52px;
      padding-top: 50px;
    }
  }
  .eight_page02 {
    font-size: 30px;
    width: 400px;
    font-weight: 700;
    color: #68ffa2;
    .eight_img {
      margin-left: 95px;
      height: 171px;
      width: 200px;
      background-size: cover;
      background-position: center;
    }
    position: absolute;
    text-align: center;
    bottom: 20%;
    left: 28%;
    .textdiv {
      font-size: 52px;
      padding-top: 50px;
    }
  }
  .eight_page03 {
    width: 400px;
    font-size: 30px;
    font-weight: 700;
    color: #ffd668;
    .eight_img {
      margin-left: 95px;
      height: 171px;
      width: 200px;
      background-size: cover;
      background-position: center;
    }
    position: absolute;
    text-align: center;
    bottom: 38%;
    left: 50%;
    .textdiv {
      font-size: 52px;
      padding-top: 50px;
    }
  }
  .eight_page04 {
    width: 400px;
    font-size: 30px;
    font-weight: 700;
    color: #ffd668;
    .eight_img {
      margin-left: 95px;
      height: 171px;
      width: 200px;
      background-size: cover;
      background-position: center;
    }
    position: absolute;
    text-align: center;
    bottom: 60%;
    left: 75%;
    .textdiv {
      font-size: 52px;
      padding-top: 50px;
    }
  }
}
.five_button {
  border-radius: 50px;
  text-align: center;
  background-size: 100% 100%;
  height: 90px;
  width: 280px;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
}
.five_button div {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  text-align: center;
  height: 80px;
}
@media (max-width: 992px) {
  .fullpage_nav {
    display: none;
  }
  .purpose_boxtext01 {
    padding-top: 1rem;
  }
  .purposeimg {
    display: block !important;

    .purpose_box {
      margin: auto;
    }
  }
  .seven_Page {
    .seven_text {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
    }
    .seven_textbg {
      position: relative;
      top: -100px;
      margin: 0 auto;
    }
  }

  .eight_Page {
    .eight_page01 {
      font-size: 20px;
      .eight_img {
        height: 113px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
    .eight_page02 {
      font-size: 20px;
      width: 300px;
      position: absolute;
      left: 18%;
      bottom: 18%;
      .eight_img {
        height: 113px;
        width: 125px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
    .eight_page03 {
      bottom: 37%;
      left: 40%;
      position: absolute;
      font-size: 20px;
      width: 300px;
      .eight_img {
        height: 113px;
        width: 125px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
    .eight_page04 {
      position: absolute;
      bottom: 64%;
      left: 66%;
      font-size: 20px;
      width: 300px;
      .eight_img {
        height: 113px;
        width: 125px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
  }

  // .item8 {
  //   .item8-main {
  //     top: 80px !important;
  //     left: 0 !important;
  //     .item8-title {
  //       margin-left: 0 !important;
  //       display: flex;
  //       flex-wrap: wrap;
  //       text-align: center;
  //       span {
  //         width: 100%;
  //       }
  //     }
  //   }
  //   .eight_page01 {
  //     right: 20px;
  //     top: 60px;
  //     left: unset;
  //     bottom: unset;
  //   }
  //   .eight_page02 {
  //     right: unset;
  //     top: 230px;
  //     left: 20px;
  //     bottom: unset;
  //     width: unset;
  //     .eight_img {
  //       margin: 0 auto !important;
  //     }
  //   }
  //   .eight_page03 {
  //     right: 0;
  //     top: 300px;
  //     left: unset;
  //     bottom: unset;
  //     width: unset;
  //     .eight_img {
  //       margin: 0 auto !important;
  //     }
  //   }
  //   .eight_page04 {
  //     right: unset;
  //     top: unset;
  //     left: 20px;
  //     bottom: 60px;
  //     width: unset;
  //     .eight_img {
  //       margin: 0 auto !important;
  //     }
  //   }
  //   .item8-img {
  //     display: none;
  //   }
  // }
}

@media (max-width: 576px) {
  .section {
    .fullpage_text {
      .textimg_box {
        .text_content {
          height: 125px;
          width: 400px;

          .text_font {
            font-size: 1.5rem;
            padding-bottom: 15px;
          }
        }
      }
    }
    .fullpage_xn {
      .xn_center {
        margin-top: 180px;
        position: relative;
        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
        .centerimg {
          height: 260px;
          width: 260px;
          position: absolute;
          top: -30%;
        }
        .center_font {
          color: #fff;
          font-size: 20px;
          position: absolute;
          top: 10px;
          background-color: rgba(48, 79, 129, 0.3);
          padding: 5px 20px;
        }

        .leftimg {
          height: 85px;
          width: 150px;
          position: absolute;
          left: 99px;
          top: 5px;
        }

        .rightimg {
          height: 85px;
          width: 150px;
          position: absolute;
          right: 99px;
          top: 5px;
        }

        .centerfont_font_left {
          text-align: center;
          color: #fff;
          font-size: 0.8rem;
          position: absolute;
          left: 125px;
          top: 40px;
          div {
            padding: 2px 0;
          }
        }
        .centerfont_font_right {
          text-align: center;
          color: #fff;
          font-size: 0.8rem;
          position: absolute;
          right: 107px;
          top: 40px;

          div {
            padding: 2px 0;
          }
        }
        .textfont01 {
          position: absolute;
          width: 102px;
          height: 44px;
          background-size: cover;
          color: #fff;
          line-height: 43px;
          text-align: center;
          font-size: 12px;
        }
      }
      .border_outer {
        height: 300px;
        width: 600px;
        border-radius: 100%;
        border: 1px dashed #fff;

        display: flex;
        align-items: center; /* 垂直居中 */
        justify-content: center; /* 水平居中 */
      }
      .border_inner {
        position: relative;
        height: 130px;
        width: 250px;
        border-radius: 100%;
        border: 1px dashed #fff;
      }
    }
  }

  .eight_Page {
    .eight_page01 {
      font-size: 20px;
      .eight_img {
        height: 113px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
    .eight_page02 {
      font-size: 20px;
      width: 300px;
      position: absolute;
      left: 18%;
      bottom: 18%;
      .eight_img {
        height: 113px;
        width: 125px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
    .eight_page03 {
      bottom: 37%;
      left: 40%;
      position: absolute;
      font-size: 20px;
      width: 300px;
      .eight_img {
        height: 113px;
        width: 125px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
    .eight_page04 {
      position: absolute;
      bottom: 67%;
      left: 50%;
      font-size: 20px;
      width: 300px;
      .eight_img {
        height: 113px;
        width: 125px;
      }
      .textdiv {
        font-size: 52px;
        padding-top: 15px;
      }
    }
  }

  .item1 {
    background-position: unset;
    /deep/ .fp-overflow {
      display: flex;
      flex-wrap: wrap;
    }
    // div:nth-child(1) {
    //   display: none;
    // }
    .setwidth {
      width: 100%;
      margin: 0;
      padding: 0 20px;
      text-align: center;
    }
    .page_introduce {
      line-height: unset;
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      padding: 0;
    }
    /deep/ #chart-panel {
      bottom: 20px !important;
      top: unset !important;
      left: 50% !important;
      right: unset !important;
      transform: translateX(-50%);
      height: 90vw !important;
    }
  }

  .item2 {
    background-position-x: 100%;
    .page_introduce {
      margin: 0;
      padding: 0 20px;
      text-align: center;
    }
  }

  .item3 {
    .fullpage_text {
      margin: 0;
      .text_content {
        float: unset;
        margin: 0 !important;
      }
    }
  }

  .item4 {
    .xn_center {
      .border_outer {
        width: calc(100vw - 2px);
      }
      .leftimg {
        left: 0% !important;
        top: 30px !important;
      }
      .centerfont_font_left {
        left: 9% !important;
        top: 65px !important;
      }

      .rightimg {
        right: 2% !important;
        top: 30px !important;
      }
      .centerfont_font_right {
        right: 4% !important;
        top: 65px !important;
      }

      .textfont01 {
        &:nth-child(1) {
          left: 50%;
          right: unset;
          transform: translateX(-50%);
        }
        &:nth-child(3) {
          left: 50% !important;
          right: unset;
          transform: translateX(-50%);
          bottom: -80px !important;
          top: unset !important;
        }
      }
    }
  }

  .item7 {
    .seven_textbg {
      width: unset !important;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      top: -70px !important;
    }
    .el-row {
      display: flex;
      flex-wrap: wrap;
      div {
        width: 50%;
        flex-shrink: 0;
        margin-bottom: 10px;
        float: unset !important;
        .seven_text {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .item8 {
    width: 100%;
    height: 100%;
    /deep/ .fp-overflow {
      width: 100%;
      height: 100%;
    }
    .item8-main {
      top: 80px !important;
      left: 0 !important;
      .item8-title {
        margin-left: 0 !important;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        span {
          width: 100%;
        }
      }
    }
    .eight_page01 {
      right: 20px;
      top: 60px;
      left: unset;
      bottom: unset;
    }
    .eight_page02 {
      right: unset;
      top: 230px;
      left: 20px;
      bottom: unset;
      width: unset;
      .eight_img {
        margin: 0 auto !important;
      }
    }
    .eight_page03 {
      right: 0;
      top: 300px;
      left: unset;
      bottom: unset;
      width: unset;
      .eight_img {
        margin: 0 auto !important;
      }
    }
    .eight_page04 {
      right: unset;
      top: unset;
      left: 20px;
      bottom: 60px;
      width: unset;
      .eight_img {
        margin: 0 auto !important;
      }
    }
    .item8-img {
      display: none;
    }
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .item1 {
    /deep/ #chart-panel {
      width: 60% !important;
      height: 60% !important;
      right: -8% !important;
      top: 58% !important;
      transform: translateY(-50%);
    }
  }

  .item2 {
    background-position-x: 100%;
    .page_introduce {
      margin: 0;
      padding: 0 20px;
      text-align: center;
    }
  }

  .item3 {
    .fullpage_text {
      margin: 0;
      .text_content {
        float: unset;
        margin: 0 !important;
      }
    }
  }

  .item4 {
    /deep/ .fp-overflow {
      overflow: hidden;
    }
    .xn_center {
      // .border_outer {
      //   width: calc(100vw - 2px);
      // }
      .leftimg {
        left: -60px !important;
        z-index: 98;
      }
      .centerfont_font_left {
        left: 0 !important;
        z-index: 99;
      }

      .rightimg {
        right: -60px !important;
        z-index: 98;
      }
      .centerfont_font_right {
        right: -35px !important;
        z-index: 99;
      }

      .textfont01 {
        &:nth-child(1) {
          left: 50%;
          right: unset;
          transform: translateX(-50%);
        }
        &:nth-child(3) {
          left: 50% !important;
          right: unset;
          transform: translateX(-50%);
          bottom: -80px !important;
          top: unset !important;
        }
      }
    }
  }

  .item7 {
    div {
      margin-top: 0 !important;
      line-height: unset !important;
      span {
        line-height: unset;
      }
    }
    .seven_textbg {
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      top: 0 !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    // .el-row {
    //   display: flex;
    //   flex-wrap: wrap;
    //   div {
    //     width: 50%;
    //     flex-shrink: 0;
    //     margin-bottom: 10px;
    //     float: unset !important;
    //     .seven_text {
    //       width: 100%;
    //       text-align: center;
    //     }
    //   }
    // }
  }
}

/deep/ .fp-watermark {
  display: none !important;
}
</style>
<style scoped>
.sectionbg06 >>> .fp-overflow {
  overflow-y: inherit;
}
</style>
<!-- .outer {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    height: 200px; /* 外层div的高度 */
    border: 1px solid #000; /* 边框，仅为了演示 */
  }

  .inner {
    width: 100px; /* 内层div的宽度 */
    height: 50px; /* 内层div的高度 */
    text-align: center; /* 文字水平居中 */
    line-height: 50px; /* 与高度相同，实现垂直居中 */
  } -->
